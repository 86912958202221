import Vue from 'vue';
import App from './App.vue';
import common from "./common";
import router from './router';
import ElementUI from 'element-ui';
import { get, post } from "./request";
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$common = common;

Vue.use(ElementUI)
Vue.config.productionTip = false

router.beforeEach((to, from, next)=>{
    // 路由发生变化时候修改页面中的title
    if(to.meta.title) {
        document.title = to.meta.title
    };
    next();
 });

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')