// request.js
import qs from "qs";
import axios from "axios";
import commit from "../common";
import {
    Message
} from 'element-ui';

axios.defaults.baseURL = commit.BASE_HOST;
axios.defaults.timeout = 50000;

axios.interceptors.response.use(
    response => {
        // 成功请求到数据
        if (response.status === 200 && response.data.error === 0) {
            return response.data;
        } else {
            Message.error(response.data.message);
        };
    },
    error => {
        //响应错误处理
        return Promise.reject(error);
    }
);

//定义方法
export function post(url, params = {}) {
    params.token = localStorage.getItem('user-token');
    return axios.post(url, qs.stringify(params));
}
export function get(url, params = {}) {
    return axios.get(url, {
        params
    });
}