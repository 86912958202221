<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "App",
};
</script>

<style>
html,
body,
#app {
    margin: 0;
    height: 100%;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
}
.el-tabs__item.is-active {
    color: #01dbf9;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before,
.el-tabs__active-bar {
    background-color: #01dbf9;
}
.el-table--border,
.el-table--group,
.el-table td,
.el-table th.is-leaf {
    border-color: #01dbf9;
}
.el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: rgba(1, 219, 249, .1);
}
</style>
