import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

export default new VueRouter({
    // mode: 'history',
    base: __dirname,
    routes: [
        // 默认路由
        {
            path: '/',
            meta: { title: '核酸检测预约' },
            component: () =>
                import ('@/views/index.vue')
        },
        {
            path: '/tips',
            meta: { title: '核酸检测提示' },
            component: () =>
                import ('@/views/tips.vue')
        },
        {
            path: '/pay',
            meta: { title: '核酸检测付款' },
            component: () =>
                import ('@/views/pay.vue')
        },
        {
            path: '/invo-00', // 微信的支付成功页面
            meta: { title: '核酸预约成功' },
            component: () =>
                import ('@/views/succ.vue')
        },
        {
            path: '/list',
            meta: { title: '核酸预约记录' },
            component: () =>
                import ('@/views/list.vue')
        }
    ]
})